<template>
  <div>
    <div id="login" v-if="!isWeixin">
      <div v-title data-title="邀请好友"></div>
      <!-- <div class="title" >邀请好友</div> -->
      <div class="login_main">
        <div class="login-img">
          <img :src="`${imgUrl}/login/appLogo2.png`" class="login_logo" />
        </div>
        <div class="login-img">
          <img :src="`${imgUrl}login/sponsor.png`" style="width:85%;margin: 10px auto;" />
        </div>

        <div class="login_form">
          <ul v-if="this.$route.path == '/login'">
            <div>
              <!-- style="display:none" -->
              <li v-if="r && r.agent">
                <span class="icon">
                  <img :src="`${imgUrl}login/accountNumber.png`" alt="" />
                </span>
                <input type="text" placeholder="代理商ID（可不填）" maxlength="8" disabled v-model="r.agent" />
              </li>
              <li>
                <span class="icon">
                  <img :src="`${imgUrl}login/accountNumber.png`" alt="" />
                </span>
                <input type="text" placeholder="用户名4-10位首位为字母" maxlength="10" v-model="r.name" @keyup="registerKeyup" />
              </li>
              <li>
                <span class="icon">
                  <img src="../assets/img/login/phone.png" alt="" />
                </span>
                <div class="countrycodebox">
                  <div class="countrycode" @click="showcountrycode = true">
                    {{ groupcurrent }}
                    <img src="../assets/img/login/left_arrow.png" />
                  </div>
                  <input style="margin-left: 5px;width: 100%;" type="text" placeholder="请输入手机号" maxlength="11"
                    v-model="r.mobile" />
                </div>

              </li>
              <li>
                <span class="icon">
                  <img src="../assets/img/login/phonecode.png" alt="" />
                </span>
                <input style="width:250px;" type="text" placeholder="请输入验证码" maxlength="8"
                  v-model="r.verification_code" />
                <span class="spantext" @click="codeClick" style="position: absolute;right: 20px;
                top: 50%;transform: translateY(-50%)">{{ codeMsg }}</span>
              </li>
              <li>
                <span class="icon">
                  <img :src="`${imgUrl}login/password.png`" alt="" />
                </span>
                <input :type="showtext" placeholder="密码长度为8-12位，字母+数字" maxlength="12" v-model="registerPass"
                  @keyup="registerKeyup" />
                <span class="showimg">
                  <img v-if="showtext === 'text'" :src="`${imgUrl}login/show.png`" alt=""
                    @click="changeshow('password')" />
                  <img v-if="showtext === 'password'" :src="`${imgUrl}login/hide.png`" alt=""
                    @click="changeshow('text')" />
                </span>
              </li>
              <li>
                <span class="icon">
                  <img :src="`${imgUrl}login/password.png`" alt="" />
                </span>
                <input :type="showtext" placeholder="请再次确认密码" maxlength="15" v-model="r.password" @keyup="registerKeyup"
                  @blur="passWordBlur" />
                <span class="showimg">
                  <img v-if="showtext === 'text'" :src="`${imgUrl}login/show.png`" alt=""
                    @click="changeshow('password')" />
                  <img v-if="showtext === 'password'" :src="`${imgUrl}login/hide.png`" alt=""
                    @click="changeshow('text')" />
                </span>
              </li>
            </div>

            <!-- <div
              class="verification"
              style="{'background':'rgba(0, 0, 0, 0.54)'}"
              @click="appCodeClick('register')"
            >
              <img
                class="safetyImg"
                v-if="!is_codeSuccess"
                :src="`${imgUrl}login/safety.png`"
                mode=""
              />
              <img
                class="safetyImg"
                v-if="is_codeSuccess"
                :src="`${imgUrl}login/safetyW.png`"
                mode=""
              />

              <span v-if="!is_codeSuccess">请点击按钮进行验证</span>
              <span v-if="is_codeSuccess" style="color: #FFFFFF;"
                >验证成功</span
              >
            </div> -->
            <div class="spantext" @click="checkClick"
              style="display: flex;justify-content: center;margin-top: 10px; margin-bottom: 20px; color:#fff">
              <span class="check" :class="{
                primary_bg_color: is_check,
                primary_border: is_check,
              }">
                <img :src="`${imgUrl}login/checking.png`" v-if="is_check" /></span>我已阅读并同意相关《条款和隐私政策》
            </div>

            <li style="margin-bottom: 14px;border:0">
              <div class="btn" @click="registerClick">
                立即注册
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="wxlogin">
      <img :src="`${imgUrl}share.png`" />
    </div>


    <van-popup v-model="showcountrycode" round position="bottom">
      <div class="countrycode-select">
        <div class="countrycode-title">
          <div class="countrycode-cancel" @click="showcountrycode = false">取消</div>
          选择国码
        </div>
        <div class="countrycode-box">
          <div class="countrycode-item" v-for="(item, index) in countrycode" :key="index" @click="groupvalue = item.code">
            <div>
              {{ item.title }} <span>{{ item.code }}</span>
            </div>

            <van-radio-group v-model="groupvalue">
              <van-radio :name="item.code"></van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="countrycode-btn" @click="selectcountrycode">
          <div class="btn-item">确认</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import 'ant-design-vue/dist/antd.min.css'

// import '../assets/js/TCaptcha.js'
import '../assets/js/awsc.js'
import '../assets/js/tool.min.js'
import countrycode from '../assets/js/bank.js'
import { verificationCodesApi, initApi, usersApi, areacodeApi } from '../network/api'
import axios from 'axios'
export default {
  data () {
    return {
      groupcurrent: '+86',
      showcountrycode: false,
      countrycode: countrycode.countrycode,
      groupvalue: '+86',
      showLoading: false,
      loginIndex: 1,
      //验证码倒计时
      // 是否禁用按钮
      codeDisabled: false,
      // 倒计时秒数
      countdown: 60,
      // 按钮上的文字
      codeMsg: '获取验证码',
      // 定时器
      timer: null,
      //
      oneen: false,
      // 登录
      l: {
        username: '',
        password: '',
        userAgent: navigator.userAgent,
        host: window.location.host,
        ip: '',
        uuid: '',
        code: '',
        ticket: '',
        randstr: '',
        appkey: '',
        deviceToken: '',
      },
      //手机登录
      phoneLogin: {
        phone: '',
        verificationCode: '',
      },
      //手机注册
      phoneRegister: {
        phone: '',
        verificationCode: '',
      },
      loginDis: true,
      loginText: '登录',
      showLoginCode: false,
      is_codeSuccess: false,
      // 注册
      registerPass: '',
      r: {
        agent: '',
        AgentHostKey: '',
        realname: '',
        name: '',
        password: '',
        is_phonemsg: 0,
        u_code: '',
        ticket: '',
        randstr: '',
        appkey: '',
        mobile: '',
        verification_code: '',
        verification_key: '',
        code: ''
      },
      showPassAtypism: false,
      is_check: true,
      registerDis: true,
      //
      wm: null,
      f_img: '',
      islogin: false,
      showtext: 'password',
      isWeixin: false,
    }
  },
  methods: {
    getareacode () {
      areacodeApi().then(res => {
        this.countrycode = res.countrycode
        this.groupcurrent = res.currentcode
        this.groupvalue = res.currentcode
      })
    },
    // 初始化
    init () {
      var _this = this
      initApi(_this.r).then(res => {
        _this.l.appkey = res.message.f_txyzm
        _this.r.appkey = res.message.f_txyzm
        var f_img = res.message.f_img
        _this.f_img = f_img
        _this.$cookies.set('f_img', f_img)
      })
      // axios.get('https://spai.asoot1288.xyz:8551/api/init', _this.r).then((res) => {
      //   _this.l.appkey = res.data.message.f_txyzm
      //   _this.r.appkey = res.data.message.f_txyzm
      //   var f_img = res.data.message.f_img
      //   _this.f_img = f_img
      //   _this.$cookies.set('f_img', f_img)
      // })

      initWatchman({
        productNumber: 'YD00019631755680',
        onload: function (instance) {
          _this.wm = instance
        },
        onerror: function (err) { },
      })
    },

    // 获取验证码
    codeClick () {
      var _this = this
      // 手机正则表达式
      var tel = /^1[23456789]\d{9}$/
      if (_this.r.mobile == '') {
        this.$message.warning('手机号码不能为空')
      } else if (!tel.test(_this.r.mobile) && _this.groupcurrent == '+86') {
        this.$message.warning('请输入正确的手机号码')
      } else {
        if (_this.codeMsg == '获取验证码' || _this.codeMsg == '重发验证码') {
          _this.shortMessageApi()
        } else {
          _this.$message.error('发送过频繁，倒计时完后在发送，谢谢！')
        }
      }
    },

    shortMessageApi () {
      var _this = this
      _this.codeMsg = '发送中'
      var data = {
        mobile: _this.r.mobile,
        code: _this.groupcurrent.split('+')[1]
      }
      verificationCodesApi(data).then(res => {
        _this.r.verification_key = res.key
        var count = 90
        _this.set = setInterval(function () {
          count--
          _this.codeMsg = count + 's'
          if (count == 0) {
            clearInterval(_this.set)
            _this.codeMsg = '重发验证码'
          }
        }, 1000)
      }).catch(err => {
        _this.codeMsg = '获取验证码'
        _this.$message.error(err.message)
      })
      // axios
      //   .post('https://spai.asoot1288.xyz:8551/api/verificationCodes', data)
      //   .then((res) => {
      //     _this.r.verification_key = res.data.key
      //     var count = 90
      //     _this.set = setInterval(function () {
      //       count--
      //       _this.codeMsg = count + 's'
      //       if (count == 0) {
      //         clearInterval(_this.set)
      //         _this.codeMsg = '重发验证码'
      //       }
      //     }, 1000)
      //   })
      //   .catch((err) => {
      //     _this.codeMsg = '获取验证码'
      //     _this.$message.error(err.response.data.message)
      //   })
    },
    //显示隐藏
    changeshow (type) {
      if (type === 'text') {
        this.showtext = 'text'
      } else if (type === 'password') {
        this.showtext = 'password'
      }
    },
    // 验证
    appCodeClick (type) {
      if (type === 'register') {
        let reg = /^[a-zA-Z][^\u4e00-\u9fa5]+$/
        var tel = /^1[3456789]\d{9}$/
        if (!reg.test(this.r.name)) {
          this.$message.error('用户名4-10位首位为字母，字母+数字')
          return
        }

        if (!this.r.name) {
          this.$message.error('请输入用户名')
          return
        }
        if (this.r.name.length < 4) {
          this.$message.error('用户名4-10位首位为字母，字母+数字')
          return
        }

        if (!tel.test(this.r.mobile)) {
          this.$message.warning('请输入正确的手机号码')
          return
        }
        if (!this.r.verification_key.length) {
          this.$message.warning('请获取验证码')
          return
        }
        if (this.r.verification_code.length < 4) {
          this.$message.warning('请输入正确的验证码')
          return
        }

        if (!this.registerPass) {
          this.$message.error('请输入密码')
          return
        }
        if (this.registerPass.length < 8) {
          this.$message.error('密码必须8-15个英、数字符')
          return
        }
        if (!this.r.password) {
          this.$message.error('请再次确认密码')
          return
        }
        if (this.registerPass !== this.r.password) {
          this.$message.error('两次密码不一致')
          return
        }
        // if (this.is_codeSuccess) {
        //   this.$message.success('你已验证无需再验证')
        //   return
        // }
      }
      var _this = this
      var captcha1 = new TencentCaptcha(_this.l.appkey, function (res) {
        if (res.ret === 0) {
          if (type == 'login') {
            _this.l.ticket = res.ticket
            _this.l.randstr = res.randstr
          } else {
            _this.r.ticket = res.ticket
            _this.r.randstr = res.randstr
          }
          _this.is_codeSuccess = true
        }
      })
      captcha1.show()
    },
    registerKeyup () {
      let reg = /^[a-zA-Z][^\u4e00-\u9fa5]+$/
      if (!reg.test(this.r.name)) {
        this.oneen = true
      } else {
        this.oneen = false
      }
      if (this.r.password.length < 8) {
        this.showPassAtypism = false
      }
      if (
        this.r.name.length < 3 ||
        this.registerPass.length < 8 ||
        this.r.password.length < 8 ||
        !this.is_check
      ) {
        this.registerDis = true
      } else {
        this.registerDis = false
      }
      // 新加的
      this.is_codeSuccess = false
    },
    checkClick () {
      this.is_check = !this.is_check
      this.registerKeyup()
    },
    passWordBlur () {
      if (this.r.password.length > 5) {
        if (this.r.password != this.registerPass) {
          this.showPassAtypism = true
        } else {
          this.showPassAtypism = false
        }
      }
    },
    registerClick () {
      let reg = /^[a-zA-Z][^\u4e00-\u9fa5]+$/
      var tel = /^1[3456789]\d{9}$/
      var _this = this
      if (!reg.test(this.r.name)) {
        this.$message.error('用户名4-10位首位为字母，字母+数字')
        return
      } else if (!tel.test(this.r.mobile) && _this.groupcurrent == '+86') {
        this.$message.warning('请输入正确的手机号码')
        return
      }
      if (this.r.password != this.registerPass) {
        this.$message.error('密码不一致')
      } else {
        this.r.realname = this.r.name.slice(0, 3) + '会员'
        this.r.code = this.groupcurrent.split('+')[1]
        usersApi(_this.r).then(res => {
          if (res.status_code === 200) {
            this.$message.success(res.message)
            window.location.href = 'https://m.be661.com/#/pages/login/login'
          }
        }).catch(err => {
          this.$message.error(err.message)
        })
      }
    },

    selectcountrycode () {
      this.showcountrycode = false
      this.groupcurrent = this.groupvalue
    },
  },
  mounted () {
    // this.r.AgentHostKey  = window.location.host
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) {
      this.isWeixin = true
      return
    }

    this.init()
    this.getareacode()

    // 获取ip
    this.h = this.headerMenuState
    const s = document.createElement('script')
    s.type = 'text/javascript'
    if (window.location.protocol == 'http:') {
      s.src = 'http://pv.sohu.com/cityjson?ie=utf-8'
    }
    if (window.location.protocol == 'https:') {
      s.src = 'https://pv.sohu.com/cityjson?ie=utf-8'
    }
    document.body.appendChild(s)

    // if (this.$cookies.isKey('AgentCode') == true) {
    //   this.r.agent = this.$cookies.get('AgentCode')
    // }

    // 链接带u_code
    this.r.u_code = this.$router.history.current.query.u_code
    this.r.agent = this.$router.history.current.query.u_code
  },
  watch: {
    $route (to, from) {
      this.is_codeSuccess = false
      this.showLoginCode = false
      this.l.username = ''
      this.l.password = ''
      this.l.ticket = ''
      this.l.randstr = ''
      this.r.realname = ''
      this.r.name = ''
      this.registerPass = ''
      this.r.password = ''
      this.r.ticket = ''
      this.r.randstr = ''
      this.r.code = ''
      this.showPassAtypism = false
    },
  },
}
</script>

<style scoped lang="less">
.countrycode-select {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;

  .countrycode-title {
    text-align: center;
    font-weight: 600;
    padding: 10px 0;
    position: relative;

    .countrycode-cancel {
      position: absolute;
      left: 20px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 3px;
      color: #999;
    }
  }

  .countrycode-box {
    padding: 0 15px;
    margin-top: 10px;
    max-height: 50vh;
    width: calc(100% - 30px);
    overflow-y: scroll;
    padding-bottom: 85px;

    .countrycode-item {
      font-size: 15px;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #999;
        margin-left: 10px;
      }
    }
  }

  .countrycode-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 25px;
    background-color: #fff;
  }

  .btn-item {
    width: 100%;
    margin: 0 auto;
    height: 40px;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-radius: 40px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    letter-spacing: 2px;
  }
}

.login_main {
  width: 100%;

  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // text-align: center;
  .login_form {
    background-color: rgba(244, 244, 244, 0.3);
    border-radius: 10px;
    padding: 15px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5px;
  }

  ul li {
    width: 100%;
    height: 36px;
    margin-bottom: 13px;
    border-radius: 50px;
    color: #fff;
    position: relative;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.54);

    input {
      width: calc(100% - 80px);
      border: 0;
      background: transparent !important;
      height: 36px;
      text-align: left;
      font-size: 14px;
      font-family: PingFang SC;
      margin-left: 40px;
    }

    .icon img {
      position: absolute;
      top: 18px;
      left: 22px;
      transform: translate(-50%, -50%);
      width: 18px;
    }
  }

  .verification {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background-color: rgba(0, 0, 0, 0.54);
    opacity: 0.8;
    border-radius: 50px;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c0c4cc;
    }
  }
}

.wxlogin {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.wxlogin img {
  width: 100%;
  display: flex;
}

.title {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
}

#login {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #232c3b;
  background-image: url('../assets/img/login/loginBgc.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.login-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login_video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
}

.login_main img.login_logo {
  width: 80px;
  margin-bottom: 20px;
}

.spantext {
  font-size: 14px;
}

.login_form .top_select {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #dddddd;
}

.login_form .top_select div {
  cursor: pointer;
}

.login_form .top_select .text_y {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.login_form .top_select .line_y {
  width: 40px;
  margin: 10px auto 0;
  height: 2px;
  background: #e3af6b;
  border-radius: 4px;
}

.login_form .top_select .text_n {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #dddddd;
}

.login_form .top_select .line_n {
  width: 40px;
  margin: 10px auto 0;
  height: 2px;
  background: transparent;
  border-radius: 4px;
}

.login_form ul li.code_success input {
  border: 1px #00b40c solid;
  background-color: rgb(0 180 12 / 35%);
}

.login_form ul li.code_success span.icon {
  background-color: #a8ffae;
}

.login_form ul li.code_success input::-webkit-input-placeholder,
.login_form ul li.code_success input::-webkit-input-placeholder,
.login_form ul li.code_success span.icon i {
  color: #00b40c;
}

.login_form ul li.code input {
  cursor: pointer;
}

.login_form ul li .getCode {
  position: absolute;
  top: 20px;
  right: 0px;
  transform: translate(0%, -50%);
  height: 42px;
  line-height: 42px;
  width: 100px;
  background-color: #fff;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #e3af6b;
  border-radius: 20px;
  cursor: pointer;
}

.login_form ul li p {
  text-align: left;
}

.login_form ul li .other_input {
  width: 300px;
  height: 44px;
  display: flex;
}

.login_form ul li .other_f {
  height: 44px;
  display: flex;
}

.login_form ul li .other_input input {
  width: 245px;
  height: 44px;
  border-radius: 0 4px 4px 0;
}

.login_form ul .other_li {
  height: 30px;
  margin-bottom: 35px;
  color: #fff;
}

.login_form ul li .other_input .send_verification_code {
  height: 44px;
  width: 100px;
  border-radius: 0 4px 4px 0;
  background-color: #5872e0;
  line-height: 44px;
  align-items: center;
}

.login_form ul li input:focus {
  color: #fff;
}

.login_form ul li input::-webkit-input-placeholder {
  color: #a9acb3;
}

.login_form ul li button {
  width: 100%;
}

.login_form ul .left {
  color: #fff;
}

.login_form ul li:last-child a {
  cursor: pointer;
}

.login_service {
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.login_service img {
  width: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.login_form ul span.check {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px #fff solid;
  display: block;
  position: relative;
  float: left;
  margin-right: 10px;
  margin-top: 2px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.login_form ul li .ant-btn-primary[disabled],
.login_form ul li .ant-btn-primary[disabled]:hover {
  opacity: 0.5;
  border-radius: 40px;
  background-color: #f1ceac;
}

.btn {
  width: 608rpx;
  margin: 0px auto 0;
  height: 40px;
  border-radius: 7px;
  font-size: 16px;
  background: linear-gradient(0deg, #2a6afd, #63c6ff);
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
}

.safetyImg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-left: 14px;
}

.showimg {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 20px;
}

.showimg img {
  width: 16px;
}

.red {
  font-size: 10px;
}

.countrycodebox {
  display: flex;
  width: calc(100% - 80px);
  margin-left: 40px;
}

.countrycode {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;
}

.countrycode img {
  position: absolute !important;
  right: 0px;
  margin-top: 3px;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
}
</style>
