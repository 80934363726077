
let countrycode = [{
  title: '中国',
  code: '+86'
}, {
  title: '香港',
  code: '+852'
}, {
  title: '越南',
  code: '+84'
}, {
  title: '泰国',
  code: '+66'
}, {
  title: '日本',
  code: '+81'
}, {
  title: '韩国',
  code: '+82'
}, {
  title: '新加坡',
  code: '+65'
}, {
  title: '马来西亚',
  code: '+60'
}, {
  title: '菲律宾',
  code: '+63'
}, {
  title: '柬埔寨',
  code: '+855'
}, {
  title: '巴西',
  code: '+55'
}, {
  title: '荷兰',
  code: '+31'
}, {
  title: '刚果',
  code: '+242'
}, {
  title: '智利',
  code: '+56'
}, {
  title: '乌拉圭',
  code: '+598'
}, {
  title: '乌克兰',
  code: '+380'
}, {
  title: '巴拿马',
  code: '+507'
}, {
  title: '委内瑞拉',
  code: '+58'
}, {
  title: '荷兰',
  code: '+31'
}, {
  title: '哥伦比亚',
  code: '+57'
}, {
  title: '哥斯达黎加',
  code: '+506'
}, {
  title: '墨西哥',
  code: '+52'
}, {
  title: '俄罗斯',
  code: '+7'
}, {
  title: '英国',
  code: '+44'
}, {
  title: '印度尼西亚',
  code: '+62'
}, {
  title: '叙利亚',
  code: '+963'
}]
export default {
  countrycode
}
